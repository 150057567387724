import React from 'react'

import Fragment from 'react-dot-fragment'

const CardWithImage = function (props) {
  const {
    id,
    cardClass,
    children,
    padding,
    width,
    imageAlt,
    imageClass,
    imageUrl,
    url
  } = props

  return (
    <div id={id} key={`card-img-${id}`} className={`${width || 'max-w-sm'} bg-white ${cardClass}`}>
      <a href={url || '#'} target="_blank">
        <img src={imageUrl} alt={imageAlt || ''} className={`w-full object-cover ${imageClass}`} />
      </a>
      <div className={padding || 'p-5'}>
        {children}
      </div>
    </div>
  )
}

export default CardWithImage
